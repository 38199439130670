import { useEffect, useState } from "react";
import { useAuth } from "../Auth";
import {
  Container,
  Body,
  Content,
  ContentHeader,
  SettingFormContainer,
} from "../components/Container.Style";

import Header from "../components/Header/Header";
import Button from "../components/Input/Button";
import ModalInput from "../components/Modal/ModalInput";
import Sidebar from "../components/Sidebar/Sidebar";

import authServices from "../services/auth.services";

function Settings() {
  const { data, setData } = useAuth();
  const [user, setUser] = useState({});
  const [passwords, setPasswords] = useState({});
  const [progress, setProgress] = useState(false);

  function handleInput(event) {
    if (["name", "email"].includes(event.target.name)) {
      user[event.target.name] = event.target.value;
      setUser({ ...user });
    }
    if (["currentPassword", "newPassword"].includes(event.target.name)) {
      passwords[event.target.name] = event.target.value;
      setPasswords({ ...passwords });
    }
  }

  function updateProfile() {
    if (progress) return;
    setProgress(true);
    authServices
      .update(user)
      .then(() => {
        alert("Profile Updated");
        data.user = { ...data.user, name: user.name, email: user.email };
        setData({ ...data });
      })
      .catch((err) => {
        alert(err.message);
      })
      .finally(() => setProgress(false));
  }

  function updatePassword() {
    if (progress) return;
    setProgress(true);
    authServices
      .updatePassword(passwords)
      .then(() => {
        alert("Password Updated");
        setPasswords({});
      })
      .catch((err) => {
        alert(err.message);
      })
      .finally(() => setProgress(false));
  }

  useEffect(() => {
    setUser({
      name: data?.user?.name || "",
      email: data?.user?.email || "",
    });
  }, [data.user]);

  return (
    <Container>
      <Header name={""} />
      <Body>
        <Sidebar />
        <Content>
          <ContentHeader>
            <div className="head-block">
              <h3>Settings</h3>
            </div>
          </ContentHeader>
          <SettingFormContainer>
            <h3>Personal Info</h3>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "48.5% 48.5%",
                gridGap: "3%",
              }}
            >
              <ModalInput
                name="name"
                label={"Name"}
                value={user.name}
                onChange={handleInput}
              />
              <ModalInput
                name="email"
                label={"Email"}
                value={user.email}
                onChange={handleInput}
              />
            </div>
            <Button
              btnType={`${progress ? "disabled" : ""} settingBtn`}
              name={progress ? "Saving..." : "Save"}
              onClick={updateProfile}
            />
            <br />
            <h3>Reset Password</h3>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "48.5% 48.5%",
                gridGap: "3%",
              }}
            >
              <ModalInput
                name="currentPassword"
                label={"Current Password"}
                value={passwords?.currentPassword || ""}
                onChange={handleInput}
                type="password"
              />
              <ModalInput
                name="newPassword"
                label={"New Password"}
                value={passwords?.newPassword || ""}
                onChange={handleInput}
                type="password"
              />
            </div>
            <Button
              btnType={`${progress ? "disabled" : ""} settingBtn`}
              name="Reset Password"
              onClick={updatePassword}
            />
          </SettingFormContainer>
        </Content>
      </Body>
    </Container>
  );
}

export default Settings;
