import React, { useEffect, useState } from "react";

const FileFolderManager = ({
  files,
  folderIndex,
  setFilesStructure,
  folders,
}) => {
  const [selectedFile, setSelectedFile] = useState("");
  const [updatedFolder, setUpdatedFolder] = useState(folders[folderIndex]);

  const handleAddFile = () => {
    if (selectedFile) {
      setUpdatedFolder({
        ...updatedFolder,
        files: [...updatedFolder.files, files[selectedFile]],
      });
    }
  };

  const handleRemoveFile = (index) => {
    let files = JSON.parse(JSON.stringify(updatedFolder.files));
    files.splice(index, 1);
    setUpdatedFolder({
      ...updatedFolder,
      files,
    });
  };

  useEffect(() => {
    const _folders = JSON.parse(JSON.stringify(folders));
    _folders[folderIndex] = JSON.parse(JSON.stringify(updatedFolder));
    setFilesStructure(_folders);
    // console.log("updatedFolder", updatedFolder);
  }, [updatedFolder]);

  return (
    <div>
      <h2>Manage Files in Folder</h2>

      <div>
        <label>Select File:</label>
        <select
          value={selectedFile}
          onChange={(e) => setSelectedFile(e.target.value)}
        >
          <option value="">-- Select File --</option>
          {files.map((file, index) => (
            <option key={file.name} value={index}>
              {file.name}
            </option>
          ))}
        </select>
      </div>

      <button onClick={handleAddFile}>Add File to Folder</button>

      <div>
        <h3>Files in "{updatedFolder.name}"</h3>
        {updatedFolder.files.length === 0 ? (
          <p>No files in this folder</p>
        ) : (
          <ul>
            {updatedFolder.files.map((file, index) => (
              <li key={file}>
                {file.name}{" "}
                <button onClick={() => handleRemoveFile(index)}>Remove</button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default FileFolderManager;
