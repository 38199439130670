import React, { useEffect, useState } from "react";
import FileFolderManager from "./FileFolderManager";

const FileExplorer = ({ files }) => {
  const [filesStructure, setFilesStructure] = useState([
    {
      name: "Session 1",
      image: "https://api.networkingtag.com/static/folder_2.png",
      parent: "",
      files: [],
      newSubFolderName: "",
    },
    {
      name: "Session 2",
      image: "https://api.networkingtag.com/static/folder_2.png",
      parent: "",
      files: [],
      newSubFolderName: "",
    },
  ]);

  const [selectedSubFolder, setSelectedSubFolder] = useState(null);

  const createSubFolder = (parentName, subFolderName) => {
    if (subFolderName.trim() === "") {
      return;
    }

    const _filesStructure = [...filesStructure];

    if (parentName == "Session 1") {
      const nameAfter = "Session 1";
      const nameBefore = "Session 2";

      // Find the indices of the specific session names
      const indexAfter = _filesStructure.findIndex(
        (obj) => obj.name === nameAfter
      );
      const indexBefore = _filesStructure.findIndex(
        (obj) => obj.name === nameBefore
      );

      if (indexAfter !== -1 && indexBefore !== -1 && indexAfter < indexBefore) {
        const newObject = {
          name: subFolderName,
          image: "",
          parent: parentName,
          files: [],
        };

        _filesStructure.splice(indexAfter + 1, 0, newObject);

        const newObjectIndex = _filesStructure.findIndex(
          (obj) => obj.name === newObject.name
        );
        const removedObject = _filesStructure.splice(newObjectIndex, 1)[0];
        _filesStructure.splice(indexBefore, 0, removedObject);
      }
    } else {
      _filesStructure.push({
        name: subFolderName,
        image: "",
        parent: parentName,
        files: [],
      });
    }

    setFilesStructure(_filesStructure);
  };

  const removeSubFolder = (index) => {
    const _filesStructure = [...filesStructure];

    _filesStructure.splice(index, 1);

    setFilesStructure(_filesStructure);
  };

  // Function to handle input change for each folder's subfolder name
  const handleInputChange = (parentName, value) => {
    setFilesStructure((prevStructure) => {
      return prevStructure.map((folder) => {
        if (folder.name === parentName) {
          folder.newSubFolderName = value; // Set input value for the specific folder
        }
        return folder;
      });
    });
  };

  // Function to handle subfolder selection
  const handleSubFolderClick = (index) => {
    setSelectedSubFolder(index);
  };

  // Render the folder structure
  const renderFolders = (folders) => {
    return folders.map((folder, index) => (
      <>
        {folder.parent == "" ? (
          <div key={`${index}_${folder.name}`} style={{ marginLeft: 20 }}>
            <div style={{ fontWeight: "bold" }}>{folder.name}</div>

            <div style={{ marginTop: 10 }}>
              <input
                type="text"
                placeholder="Enter subfolder name"
                value={folder.newSubFolderName} // Use folder-specific input value
                onChange={(e) => handleInputChange(folder.name, e.target.value)} // Handle change for specific folder
                style={{ marginRight: 10 }}
              />
              <button
                onClick={() =>
                  createSubFolder(folder.name, folder.newSubFolderName)
                }
              >
                Add Subfolder
              </button>
            </div>
          </div>
        ) : (
          <div style={{}}>
            <div
              key={`${index}_${folder.name}`}
              style={{ marginLeft: 20, cursor: "pointer" }}
              onClick={() => handleSubFolderClick(index)}
            >
              <span>{folder.name}</span>
              <button
                onClick={() => removeSubFolder(index)}
                style={{ marginLeft: 10 }}
              >
                Remove
              </button>
            </div>
          </div>
        )}
      </>
    ));
  };

  useEffect(() => {
    console.log("filesStructure", filesStructure);
  }, [filesStructure]);

  return (
    <div style={{ display: "flex", borderRadius: "20px" }}>
      {/* Left Side: Folder Structure */}
      <div
        style={{
          width: "300px",
          backgroundColor: "#f4f4f4",
          padding: "10px",
          borderTopLeftRadius: "20px",
        }}
      >
        <h3>Resource Structure</h3>
        {renderFolders(filesStructure)}
      </div>

      {/* Right Side: Content for selected folder */}
      <div
        style={{
          flex: 1,
          padding: "10px",
          borderLeft: "1px solid #ddd",
          borderTopRightRadius: "20px",
        }}
      >
        <h3>Details of Selected Subfolder</h3>
        {selectedSubFolder ? (
          <div>
            <strong>Selected Subfolder:</strong>{" "}
            {filesStructure[selectedSubFolder].name}
            <FileFolderManager
              files={files}
              folderIndex={selectedSubFolder}
              setFilesStructure={setFilesStructure}
              folders={filesStructure}
            />
          </div>
        ) : (
          <div>Select a subfolder to view details.</div>
        )}
      </div>
    </div>
  );
};

export default FileExplorer;
