import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Body,
  Content,
  ContentHeader,
  PlaceHolder,
  ActionWrapper,
} from "../components/Container.Style";
import {
  Table,
  TableBody,
  TableHeader,
  Td,
  Tr,
} from "../components/Table.Style";

import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import Button from "../components/Input/Button";
import FileInput from "../components/Input/FileInput";
import Search from "../components/Input/Search";
import Modal from "../components/Modal/Modal";
import { ModalForm } from "../components/Modal/Modal.Style";
import ModalInput from "../components/Modal/ModalInput";
import ConferenceInfo from "../components/Conferences/ConferenceInfo";
import Agendas from "../components/Conferences/Agendas";
import Sponsorships from "../components/Conferences/Sponsorships";
import ResourceFiles from "../components/Conferences/Sponsorships1";
import Exhibits from "../components/Conferences/Exhibits";

import ImportIcon from "../assets/images/Import.svg";
import ExportIcon from "../assets/images/export.svg";
import TrashIcon from "../assets/images/trash-1.svg";
import EditIcon from "../assets/images/edit-1.svg";
import NotificationIcon from "../assets/images/notification.svg";

import attendeeServices from "../services/attendee.services";
import conferenceServices from "../services/conference.services";
import exhibitServices from "../services/exhibit.services";
import PushNotificationModal from "../components/PushNotificationModal";

function Conference() {
  const params = useParams();

  const [conference, setConference] = useState({ sponsorships: [] });
  const [attendees, setAttendees] = useState([]);
  const [agendas, setAgendas] = useState([]);
  const [exhibits, setExhibits] = useState([]);

  const [search, setSearch] = useState("");
  const [image, setImage] = useState({
    file: null,
    dataURL: null,
    progress: false,
  });
  const [progress, setProgress] = useState({
    inProgress: false,
    message: "",
  });
  const [deletePopup, setDeletePopup] = useState({
    open: false,
    attendee: null,
  });
  const [updatePopup, setUpdatePopup] = useState({
    open: false,
    attendee: null,
  });

  const ITEMS_PER_PAGE = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(attendees.length / ITEMS_PER_PAGE);
  const indexOfLastAttendee = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstAttendee = indexOfLastAttendee - ITEMS_PER_PAGE;
  const currentAttendees = attendees.slice(
    indexOfFirstAttendee,
    indexOfLastAttendee
  );
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  //========================================

  const [checkedItems, setCheckedItems] = useState([]);
  const [checkedItemsDetails, setCheckedItemsDetails] = useState([]);

  const handleCheckboxChange = (id, name, notification_token) => {
    setCheckedItems((prevCheckedItems) => {
      if (prevCheckedItems.includes(id)) {
        const updatedCheckedItems = prevCheckedItems.filter(
          (item) => item !== id
        );
        const updatedCheckedItemsDetails = { ...checkedItemsDetails };
        delete updatedCheckedItemsDetails[id];
        setCheckedItemsDetails(updatedCheckedItemsDetails);
        return updatedCheckedItems;
      } else {
        const updatedCheckedItems = [...prevCheckedItems, id];
        setCheckedItemsDetails((prevDetails) => ({
          ...prevDetails,
          [id]: { name, notification_token },
        }));
        return updatedCheckedItems;
      }
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setCheckedItems(attendees.map((item) => item._id));
      const allDetails = attendees.reduce((acc, item) => {
        acc[item._id] = {
          name: item.name,
          notification_token: item.notifications_token,
        };
        return acc;
      }, {});
      setCheckedItemsDetails(allDetails);
    } else {
      // Deselect all
      setCheckedItems([]);
      setCheckedItemsDetails({});
    }
  };

  const isAllSelected =
    attendees.length > 0 && checkedItems.length === attendees.length;
  const isSomeSelected =
    checkedItems.length > 0 && checkedItems.length < attendees.length;

  // useEffect(() => {
  //   console.log(checkedItems, checkedItemsDetails);
  // }, [checkedItems, checkedItemsDetails]);

  //========================================

  function handleInput(e) {
    updatePopup.attendee[e.target.name] = e.target.value;
    setUpdatePopup({ open: true, attendee: { ...updatePopup.attendee } });
  }

  function removeAttendee() {
    if (progress.inProgress) return;
    setProgress({ inProgress: true, message: "Please wait removing attendee" });
    attendeeServices
      .removeAttendee(deletePopup.attendee?._id, conference._id)
      .then(() => {
        const atends = attendees.filter(
          (s) => `${s._id}` !== `${deletePopup.attendee._id}`
        );
        setAttendees([...atends]);
        setDeletePopup({ open: false, attendee: null });
      })
      .catch((err) => alert(err.message))
      .finally(() => setProgress({ inProgress: false, message: "" }));
  }

  function updateAttendee() {
    if (progress.inProgress) return;
    setProgress({ inProgress: true, message: "Please wait removing attendee" });
    attendeeServices
      .updateAttendee(updatePopup.attendee?._id, {
        name: updatePopup.attendee.name,
        email: updatePopup.attendee.email,
        speaker_profile_link: updatePopup.attendee.speaker_profile_link,
        team_leader: updatePopup.attendee.team_leader,
        sponsor: updatePopup.attendee.sponsor,
      })
      .then(() => {
        const attendeeIndex = attendees.findIndex(
          (attendee) => `${attendee._id}` === `${updatePopup.attendee?._id}`
        );
        attendees[attendeeIndex] = updatePopup.attendee;
        setUpdatePopup({ open: false, attendee: null });
      })
      .catch((err) => alert(err.message))
      .finally(() => setProgress({ inProgress: false, message: "" }));
  }

  function exportAttendees() {
    if (progress.inProgress) return;
    setProgress({
      inProgress: true,
      message: "Please wait, exporting attendees!",
    });
    attendeeServices
      .exportAttendees(params.id)
      .catch((err) => {
        alert(err.message);
      })
      .finally(() => {
        setProgress({
          inProgress: true,
          message: "Please wait, exporting attendees!",
        });
      });
  }

  function importAttendees(event) {
    attendeeServices
      .importAttendees(event.target.files[0], params.id)
      .then((res) => {
        setAttendees([...attendees, ...res.data.attendees]);
      })
      .catch((err) => alert(err.message));
  }

  const addConferenceId = (email, conferenceId) => {
    const emailParts = email.split("@");

    if (emailParts.length == 2) {
      return `${emailParts[0]}+${conferenceId}@${emailParts[1]}`;
    } else {
      return email;
    }
  };

  function importAttendeesTeam(event) {
    attendeeServices
      .importAttendeesTeam(event.target.files[0], params.id)
      .then((res) => {
        //update attendees team
        for (let team of Object.keys(res.teams)) {
          const users = res.teams[team];
          users.forEach((user) => {
            const userIndex = attendees.findIndex((attend) => {
              const userEmail = addConferenceId(user, params.id);
              return attend.email === userEmail;
            });
            if (userIndex < 0) return;
            attendees[userIndex].team = team;
          });
        }
        setAttendees([...attendees]);
        window.location.reload();
      })
      .catch((err) => alert(err.message));
  }

  async function uploadImage(event) {
    image.file = event.target.files[0];
    image.progress = true;
    setImage({ ...image });
  }

  useEffect(() => {
    //FETCH ATTENDEES
    attendeeServices
      .getAttendees(`conferenceId=${params.id}`)
      .then((response) => {
        setAttendees(response.data.attendees);
      })
      .catch((err) => alert(err.message));

    //FETCH AGENDAS
    conferenceServices
      .fetchAgendas(params.id)
      .then((res) => {
        setAgendas(res.data.agendas);
      })
      .catch((err) => alert(err.message));

    //Fetch Exhibits
    exhibitServices
      .fetchExhibits(params.id)
      .then((res) => setExhibits(res.data.exhibits))
      .catch((err) => console.log(err.message));
  }, [params.id]);

  useEffect(() => {
    attendeeServices
      .getAttendees(
        search
          ? `name[regex]=${search}&name[options]=i&conferenceId=${params.id}`
          : `conferenceId=${params.id}`
      )
      .then((response) => {
        setAttendees(response.data.attendees);
        setCurrentPage(1);
      })
      .catch((err) => alert(err.message));
  }, [search]);

  useEffect(() => {
    if (!image.progress) return;
    conferenceServices
      .updateConferenceImage([{ field: "map", content: image.file }], params.id)
      .then((res) => {
        setConference({ ...conference, ...res.data.images });
      })
      .catch((err) => console.log(err.message))
      .finally(() => {
        setImage({ file: null, dataURL: null, progress: false });
      });
  }, [image]);

  const [arrangeResources, setArrangeResources] = useState(false);

  return (
    <Container>
      <Header name={""} />
      <Body>
        <Sidebar />
        <Content>
          <ConferenceInfo
            conferenceId={params.id}
            conference={conference}
            setConference={setConference}
          />
          <ContentHeader>
            <div className="head-block">
              <div>
                <h4>Attendees List</h4>
              </div>
              <FileInput
                icon={ImportIcon}
                onChange={importAttendees}
                name="Import"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
              <FileInput
                icon={ImportIcon}
                onChange={importAttendeesTeam}
                name="Import Team"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
              <Button
                icon={ExportIcon}
                onClick={exportAttendees}
                btnType={`${false ? "disabled" : ""} exportBtn`}
                name="Export"
              />
              <PushNotificationModal
                conferenceId={conference._id}
                attendees={checkedItemsDetails}
                button={
                  <Button
                    icon={NotificationIcon}
                    onClick={() => {}}
                    btnType={`pushNotification`}
                    name="Push Notification"
                  />
                }
              />
            </div>
            <div className="head-block right">
              <Search
                onChange={(event) => setSearch(event.target.value)}
                search={search}
              />
            </div>
          </ContentHeader>
          <>
            <br />
            {attendees.length ? (
              <Table>
                <TableHeader>
                  <Tr borderWidth={"0px"} background={"transparent"}>
                    <Td>
                      <input
                        type="checkbox"
                        checked={isAllSelected}
                        indeterminate={isSomeSelected}
                        onChange={handleSelectAll}
                      />
                    </Td>
                    <Td>S.No.</Td>
                    <Td>Name</Td>
                    <Td>Email</Td>
                    <Td>Company</Td>
                    <Td>Team</Td>
                    <Td>Registered</Td>
                    <Td></Td>
                  </Tr>
                </TableHeader>
                <TableBody>
                  {currentAttendees.map((attendee, index) => (
                    <Tr key={index}>
                      <Td>
                        <input
                          type="checkbox"
                          checked={checkedItems.includes(attendee._id)}
                          onChange={() =>
                            handleCheckboxChange(
                              attendee._id,
                              attendee.name,
                              attendee.notifications_token
                            )
                          }
                        />
                      </Td>
                      <Td>{index + 1 + indexOfFirstAttendee}</Td>
                      <Td color="#000000">{attendee.name}</Td>
                      <Td>{attendee.email.replace(/\+.*@/, "@")}</Td>
                      <Td>{attendee.company || "-"}</Td>
                      {/* <Td color={attendee.team ? "#000000" : "#a0a0a0"}>
                        {attendee.team || "Not Assigned"}
                      </Td> */}
                      <Td
                        color={
                          attendee.teams[conference._id] ? "#000000" : "#a0a0a0"
                        }
                      >
                        {attendee.teams[conference._id] || "Not Assigned"}
                      </Td>
                      <Td color={attendee.registered ? "green" : "#a0a0a0"}>
                        {attendee.registered ? "Registered" : "Pending"}
                      </Td>
                      <Td>
                        <ActionWrapper>
                          <img
                            id="edit"
                            src={EditIcon}
                            alt="edit"
                            onClick={() =>
                              setUpdatePopup({ open: true, attendee })
                            }
                          />
                          <img
                            id="remove"
                            src={TrashIcon}
                            onClick={() =>
                              setDeletePopup({ open: true, attendee })
                            }
                            alt="remove"
                          />
                        </ActionWrapper>
                      </Td>
                    </Tr>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <PlaceHolder>Import Attendees</PlaceHolder>
            )}
            <br />

            {/* Pagination controls */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                style={{
                  marginRight: "10px",
                  padding: "5px 10px",
                  cursor: currentPage === 1 ? "not-allowed" : "pointer",
                  backgroundColor: currentPage === 1 ? "#ccc" : "#007bff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                }}
              >
                Previous
              </button>

              <span style={{ margin: "0 10px", fontWeight: "bold" }}>
                Page {currentPage} of {totalPages}
              </span>

              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                style={{
                  marginLeft: "10px",
                  padding: "5px 10px",
                  cursor:
                    currentPage === totalPages ? "not-allowed" : "pointer",
                  backgroundColor:
                    currentPage === totalPages ? "#ccc" : "#007bff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                }}
              >
                Next
              </button>
            </div>
          </>

          {checkedItems.length > 0 ? (
            <div style={{ marginBottom: "80px" }}>
              <h4>Selected Attendees:</h4>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap", // Allow wrapping to a new line
                  gap: "5px", // Add gap between items
                }}
              >
                {Object.keys(checkedItemsDetails).map((id) => (
                  <span
                    style={{
                      backgroundColor: "yellow",
                      color: "black",
                      fontWeight: "bold",
                      padding: "5px 10px",
                      borderRadius: "10px",
                      margin: "5px", // Margin between items
                      display: "inline-block", // Ensure items are inline-block for wrapping
                    }}
                    key={id}
                  >
                    {checkedItemsDetails[id].name}
                  </span>
                ))}
              </div>
            </div>
          ) : null}

          <Agendas
            conferenceId={params.id}
            agendas={agendas}
            setAgendas={setAgendas}
          />
          <br />
          <ResourceFiles
            resources={true}
            conference={conference}
            setConference={setConference}
            setArrangeResourcesParent={setArrangeResources}
          />
          <br />
          <br />
          <br />
          <br />
          <br />
          {!arrangeResources ? (
            <Sponsorships
              conference={conference}
              setConference={setConference}
            />
          ) : null}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {/* <Exhibits
            conference={conference}
            exhibits={exhibits}
            setExhibits={setExhibits}
            image={image}
            setImage={setImage}
            updateImage={uploadImage}
          /> */}
        </Content>
      </Body>
      {deletePopup.open && (
        <Modal
          closeModal={() => setDeletePopup({ open: false })}
          icon={TrashIcon}
          heading="Delete"
        >
          <p>{`Are you sure you want to delete ${
            deletePopup?.attendee?.name
          } (${deletePopup.attendee?.email || ""}) ?`}</p>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              style={{ margin: "0", color: "#f1f1f1 !important" }}
              btnType="modalBtn"
              name="Cancel"
              onClick={() => setDeletePopup({ open: false })}
            />
            <Button
              style={{ margin: "0", marginLeft: "10px" }}
              btnType="modalBtn"
              name="Delete"
              onClick={removeAttendee}
            />
          </div>
        </Modal>
      )}
      {updatePopup.open && (
        <Modal
          closeModal={() => setUpdatePopup({ open: false })}
          icon={EditIcon}
          heading={`Edit Attendee`}
        >
          <ModalForm>
            <ModalInput
              name="name"
              label={"Name"}
              value={updatePopup?.attendee?.name || ""}
              placeholder={""}
              onChange={handleInput}
            />
            <ModalInput
              name="email"
              label={"Email"}
              value={updatePopup?.attendee?.email || ""}
              placeholder={""}
              onChange={handleInput}
            />

            <ModalInput
              name="speaker_profile_link"
              label={"Speaker Profile Link"}
              value={updatePopup?.attendee?.speaker_profile_link || ""}
              placeholder={""}
              onChange={handleInput}
            />

            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <div style={{ padding: "15px 0px 0 0" }}>
                <div style={{ padding: "0px 0px 5px 0px" }}>Sponsor</div>
                <ToggleButton
                  onChange={(value) => {
                    updatePopup.attendee["sponsor"] = value;
                    setUpdatePopup({
                      open: true,
                      attendee: { ...updatePopup.attendee },
                    });
                  }}
                  value={updatePopup?.attendee?.sponsor}
                />
              </div>

              <div style={{ padding: "15px 0px 0 0px" }}>
                <div style={{ padding: "0px 0px 5px 0px" }}>Team Leader</div>
                <ToggleButton
                  onChange={(value) => {
                    updatePopup.attendee["team_leader"] = value;
                    setUpdatePopup({
                      open: true,
                      attendee: { ...updatePopup.attendee },
                    });
                  }}
                  value={updatePopup?.attendee?.team_leader}
                />
              </div>
            </div>

            <Button
              btnType={`${false ? "disabled" : ""} modalBtn`}
              name="EDIT"
              onClick={updateAttendee}
            />
          </ModalForm>
        </Modal>
      )}
    </Container>
  );
}

const ToggleButton = ({ value, onChange }) => {
  const [buttonState, setButtonState] = useState(false);

  useEffect(() => {
    setButtonState(value);
  }, []);

  return (
    <label className="sfasfS_switch">
      <input
        type="checkbox"
        checked={buttonState}
        onClick={() => {
          onChange(!buttonState);
          setButtonState(!buttonState);
        }}
      />
      <span className="sfasfS_slider round"></span>
    </label>
  );
};

export default Conference;
