import { useState, useEffect, useRef } from "react";

import { ContentHeader, PlaceHolder } from "../Container.Style";
import { Grid } from "./Conference.Style";
import { ModalForm } from "../Modal/Modal.Style";

import Modal from "../Modal/Modal";
import ModalInput from "../Modal/ModalInput";
import FileInput from "../Input/FileInput";
import Button from "../Input/Button";
import Sponsorship from "./Sponsorship";

import ImportIcon from "../../assets/images/Import.svg";
import TrashIcon from "../../assets/images/trash-1.svg";
import EditIcon from "../../assets/images/edit-1.svg";

import conferenceServices from "../../services/conference.services";

function Sponsorships(props) {
  const [deletePopup, setDeletePopup] = useState({
    open: false,
    sponsor: null,
  });
  const [updatePopup, setUpdatePopup] = useState({
    open: false,
    sponsor: null,
  });
  const [progress, setProgress] = useState(false);

  useEffect(() => {
    console.log("rrrr", props.conference?.resources);
  }, [props.conference]);

  function handleInput(e) {
    updatePopup.sponsor[e.target.name] = e.target.value;
    setUpdatePopup({ open: true, sponsor: { ...updatePopup.sponsor } });
  }

  function removeSponsor() {
    if (progress) return;
    setProgress(true);
    conferenceServices
      .removeSponsor(
        props.conference?._id,
        deletePopup.sponsor?._id,
        props.resources
      )
      .then(() => {
        if (props.resources) {
          const resources = props.conference.resources.filter(
            (s) => `${s._id}` !== `${deletePopup.sponsor._id}`
          );
          props.setConference({ ...props.conference, resources });
          setDeletePopup({ open: false, sponsor: null });
        } else {
          const sponsorships = props.conference.sponsorships.filter(
            (s) => `${s._id}` !== `${deletePopup.sponsor._id}`
          );
          props.setConference({ ...props.conference, sponsorships });
          setDeletePopup({ open: false, sponsor: null });
        }
      })
      .catch((err) => alert(err.message))
      .finally(() => setProgress(false));
  }

  function updateSponsor() {
    if (progress) return;
    setProgress(true);
    conferenceServices
      .updateSponsor(
        props.conference?._id,
        updatePopup.sponsor?._id,
        props.resources,
        {
          name: updatePopup.sponsor.name,
          logo: updatePopup.sponsor.logo,
          sponsorship_type: updatePopup.sponsor.sponsorship_type,
          tier: updatePopup.sponsor.tier || "-",
          company_link: updatePopup.sponsor.company_link || "-",
          description: updatePopup.sponsor.description || "-",
        }
      )
      .then(() => {
        const sponsorshipIndex = props.conference.sponsorships.findIndex(
          (sponsor) => `${sponsor._id}` === `${updatePopup.sponsor?._id}`
        );
        props.conference.sponsorships[sponsorshipIndex] = updatePopup.sponsor;
        setUpdatePopup({ open: false, sponsor: null });
      })
      .catch((err) => alert(err.message))
      .finally(() => setProgress(false));
  }

  function importSponsorships(event) {
    conferenceServices
      .importSponsorships(
        event.target.files[0],
        props.conference?._id,
        props.resources
      )
      .then((res) => {
        if (props.resources) {
          props.setConference({
            ...props.conference,
            resources: res.data.resources,
          });
        } else {
          props.setConference({
            ...props.conference,
            sponsorships: res.data.sponsorships,
          });
        }
      })
      .catch((err) => alert(err.message));
  }

  const [arrangeResources, setArrangeResources] = useState(false);
  const ref1 = useRef();
  const ref2 = useRef();

  useEffect(() => {
    console.log(ref1, ref2);

    if (arrangeResources) {
      if (ref1?.current) {
        ref1.current.style.display = "none";
      }
      if (ref2?.current) {
        ref2.current.style.display = "none";
      }
    } else {
      if (ref1?.current) {
        ref1.current.style.display = "flex";
      }
      if (ref2?.current) {
        ref2.current.style.display = "flex";
      }
    }
  }, [arrangeResources]);

  return (
    <>
      <ContentHeader>
        <div className="head-block" ref={ref2}>
          <h4>Sponsorships List</h4>
          <FileInput
            icon={ImportIcon}
            onChange={importSponsorships}
            name="Import"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </div>
      </ContentHeader>
      <br />
      {props.conference?.sponsorships &&
      props.conference.sponsorships.length ? (
        <Grid>
          {props.conference.sponsorships.map((sponsorship, index) => (
            <Sponsorship
              key={index}
              deleteSponsor={() =>
                setDeletePopup({ open: true, sponsor: sponsorship })
              }
              updateSponsor={() =>
                setUpdatePopup({ open: true, sponsor: sponsorship })
              }
              sponsorship={sponsorship}
            />
          ))}
        </Grid>
      ) : (
        <PlaceHolder>Import Sponsorships</PlaceHolder>
      )}

      {deletePopup.open && (
        <Modal
          closeModal={() => setDeletePopup({ open: false })}
          icon={TrashIcon}
          heading="Delete"
        >
          <p>{`Are you sure you want to delete ${deletePopup?.sponsor?.name} sponsor ?`}</p>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              style={{ margin: "0", color: "#f1f1f1 !important" }}
              btnType="modalBtn"
              name="Cancel"
              onClick={() => setDeletePopup({ open: false })}
            />
            <Button
              style={{ margin: "0", marginLeft: "10px" }}
              btnType="modalBtn"
              name="Delete"
              onClick={removeSponsor}
            />
          </div>
        </Modal>
      )}
      {updatePopup.open && (
        <Modal
          closeModal={() => setUpdatePopup({ open: false })}
          icon={EditIcon}
          heading={`Edit Sponsor`}
        >
          <ModalForm>
            <ModalInput
              name="name"
              label={"Name"}
              value={updatePopup?.sponsor?.name || ""}
              placeholder={"Name"}
              onChange={handleInput}
            />
            <ModalInput
              name="logo"
              label={"Logo"}
              value={updatePopup?.sponsor?.logo || ""}
              placeholder={"Paste Sponsor url logo"}
              onChange={handleInput}
            />
            <ModalInput
              name="sponsorship_type"
              label={"Sponsorship Type"}
              value={updatePopup?.sponsor?.sponsorship_type || ""}
              placeholder={"Please add sponsorship type"}
              onChange={handleInput}
            />
            <ModalInput
              name="tier"
              label={"Tier"}
              value={updatePopup?.sponsor?.tier || ""}
              placeholder={"Please add tier"}
              onChange={handleInput}
            />
            <ModalInput
              name="company_link"
              label={"Company Link"}
              value={updatePopup?.sponsor?.company_link || ""}
              placeholder={"Please add company_link"}
              onChange={handleInput}
            />
            <ModalInput
              name="description"
              label={"Description"}
              value={updatePopup?.sponsor?.description || ""}
              placeholder={"Please add description"}
              onChange={handleInput}
              textArea={true}
            />
            <Button
              btnType={`${false ? "disabled" : ""} modalBtn`}
              name="EDIT"
              onClick={updateSponsor}
            />
          </ModalForm>
        </Modal>
      )}
    </>
  );
}

export default Sponsorships;
