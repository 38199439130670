import { useRef, useEffect, useState } from "react";

import {
  Container,
  Body,
  Content,
  ContentHeader,
} from "../components/Container.Style";
import { ConferenceWrapper } from "../components/Conferences/Conference.Style";
import Conference from "../components/Conferences/Conference";
import Button from "../components/Input/Button";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import Search from "../components/Input/Search";
import { ModalForm } from "../components/Modal/Modal.Style";
import Modal from "../components/Modal/Modal";
import ModalInput from "../components/Modal/ModalInput";

import SpeakerIcon from "../assets/images/speaker.svg";
import LocationIcon from "../assets/images/location.svg";
import CalendarIcon from "../assets/images/calender.svg";
import TimeIcon from "../assets/images/time.svg";
import MicrophoneActiveIcon from "../assets/images/microphone-active.svg";
import ExportIcon from "../assets/images/export.svg";
import { getFormattedDate, get24Time } from "../utils/date";

import conferenceServices from "../services/conference.services";
import DynamicForm from "./DynamicForm";

function Conferences() {
  const data = useRef({
    title: "Board Conference",
    hostname: "",
    address: "Ghar ke saamne ajana theek",
  });
  const [conferences, setConferences] = useState([]);
  const [modal, setModal] = useState(false);
  const [newConference, setNewConference] = useState({
    hostname: "",
    name: "",
    address: "",
    website: "",
    liveOn: null,
    endOn: null,
    version: "1.0.0",
    statusColor: "255,0,0,255",
    statusText: "Yet not started",
    androidVersion: "1.0.0",
    iosVersion: "1.0.0",
    deleted: 0,
    otherInfo: {},
  });
  const [loader, setLoader] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  useEffect(() => {
    conferenceServices
      .getConferences()
      .then((res) => {
        setConferences(res.data.conferences);
      })
      .catch((err) => console.log(err.message));
  }, []);

  const handleInput = (event) => {
    const { name, value } = event.target;
    newConference[name] = value;
    setNewConference({ ...newConference });
  };

  const handleTimeInput = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "startDate":
        const date = new Date(`${value}T00:00`);
        if (newConference.liveOn) newConference.liveOn.date = date;
        else {
          newConference.liveOn = { date };
        }
        break;
      case "startTime":
        const startTime = event.target.valueAsNumber;
        if (newConference.liveOn) newConference.liveOn.time = startTime;
        else {
          newConference.liveOn = { time: startTime };
        }
        break;
      case "endDate":
        const endDate = new Date(`${value}T00:00`);
        if (newConference.endOn) newConference.endOn.date = endDate;
        else {
          newConference.endOn = { date: endDate };
        }
        break;
      case "endTime":
        const endTime = event.target.valueAsNumber;
        if (newConference.endOn) newConference.endOn.time = endTime;
        else {
          newConference.endOn = { time: endTime };
        }
        break;
    }
    setNewConference({ ...newConference });
  };

  function validateStructure(data) {
    const errors = [];

    // Validate hostname, name, address, website, version, androidVersion, iosVersion
    const stringFields = [
      "hostname",
      "name",
      "address",
      "website",
      "version",
      "androidVersion",
      "iosVersion",
    ];
    stringFields.forEach((field) => {
      if (typeof data[field] !== "string" || data[field].trim() === "") {
        errors.push(`'${field}' must be a non-empty string.`);
      }
    });

    // Validate liveOn and endOn (date and time)
    ["liveOn", "endOn"].forEach((field) => {
      if (data[field]) {
        if (typeof data[field] !== "object") {
          errors.push(`'${field}' must be an object.`);
        } else {
          // Validate date
          const date = data[field].date;
          const time = data[field].time;
          if (!Date.parse(date)) {
            errors.push(`'${field}.date' is not a valid ISO date string.`);
          }

          // Validate time (should be a positive integer)
          if (typeof time !== "number" || time < 0) {
            errors.push(`'${field}.time' must be a positive integer.`);
          }
        }
      }
    });

    // Validate statusColor (must be valid RGBA color)
    if (
      data.statusColor &&
      !/^(\d{1,3}),(\d{1,3}),(\d{1,3}),(\d{1,3})$/.test(data.statusColor)
    ) {
      errors.push(
        "'statusColor' must be a valid RGBA color in the format R,G,B,A."
      );
    }

    // Validate statusText (non-empty string)
    if (
      data.statusText &&
      (typeof data.statusText !== "string" || data.statusText.trim() === "")
    ) {
      errors.push("'statusText' must be a non-empty string.");
    }

    // Validate deleted (should be 0 or 1)
    if (
      data.deleted !== undefined &&
      (typeof data.deleted !== "number" || ![0, 1].includes(data.deleted))
    ) {
      errors.push("'deleted' must be either 0 or 1.");
    }

    // Validate otherInfo (must have at least one object)
    // if (data.otherInfo) {
    //   if (
    //     typeof data.otherInfo !== "object" ||
    //     Object.keys(data.otherInfo).length === 0
    //   ) {
    //     errors.push("'otherInfo' must be a non-empty object.");
    //   } else {
    //     // Validate keys inside otherInfo
    //     // Object.keys(data.otherInfo).forEach((key) => {
    //     //   const value = data.otherInfo[key];
    //     //   if (typeof value !== "object") {
    //     //     errors.push(`'${key}' inside 'otherInfo' must be an object.`);
    //     //   } else {
    //     //     // Validate fields inside each object in otherInfo
    //     //     if (
    //     //       value.label &&
    //     //       (typeof value.label !== "string" || value.label.trim() === "")
    //     //     ) {
    //     //       errors.push(`'${key}.label' must be a non-empty string.`);
    //     //     }
    //     //     if (typeof value.disable !== "boolean") {
    //     //       errors.push(`'${key}.disable' must be a boolean value.`);
    //     //     }
    //     //     if (typeof value.visibility !== "boolean") {
    //     //       errors.push(`'${key}.visibility' must be a boolean value.`);
    //     //     }
    //     //     if (typeof value.outer !== "boolean") {
    //     //       errors.push(`'${key}.outer' must be a boolean value.`);
    //     //     }
    //     //     if (value.outerKey && typeof value.outerKey !== "string") {
    //     //       errors.push(`'${key}.outerKey' must be a string.`);
    //     //     }
    //     //     if (value.icon !== null && typeof value.icon !== "string") {
    //     //       errors.push(`'${key}.icon' must be a string or null.`);
    //     //     }
    //     //     // if (
    //     //     //   value.type &&
    //     //     //   !["text", "number", "date", "boolean"].includes(value.type)
    //     //     // ) {
    //     //     //   errors.push(
    //     //     //     `'${key}.type' must be one of ['text', 'number', 'date', 'boolean'].`
    //     //     //   );
    //     //     // }
    //     //     // if (value.options && !Array.isArray(value.options)) {
    //     //     //   errors.push(`'${key}.options' must be an array.`);
    //     //     // }
    //     //   }
    //     // });
    //   }
    // }

    return errors;
  }

  const createConference = () => {
    // console.log("newConference", newConference);
    const errors = validateStructure(newConference);
    setValidationErrors(errors);
    if (errors.length > 0) {
      return;
    }
    if (loader) return;
    setLoader(true);
    conferenceServices
      .createConference(newConference)
      .then(() => {
        setModal(false);
        window.location.reload();
      })
      .catch((err) => {
        setLoader(false);
        alert(err.message);
      });
  };

  return (
    <Container>
      <Header name={""} />
      <Body>
        <Sidebar />
        <Content>
          <ContentHeader>
            <div className="head-block">
              <h3>Conference</h3>
            </div>
            <div className="head-block right">
              <Search />
              <Button
                onClick={() => setModal(!modal)}
                btnType={`${false ? "disabled" : ""} conferenceBtn`}
                name="Create Conference"
              />
            </div>
          </ContentHeader>
          <ConferenceWrapper>
            {conferences.map((conference, index) => {
              return !conference.deleted ? (
                <Conference {...conference} key={index} />
              ) : null;
            })}
          </ConferenceWrapper>
          {modal && (
            <Modal
              width={"50%"}
              icon={MicrophoneActiveIcon}
              heading={"Add Conference"}
              closeModal={() => setModal(!modal)}
            >
              <ModalForm>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "48.5% 48.5%",
                    gridGap: "3%",
                  }}
                >
                  <ModalInput
                    name="name"
                    label={"Name Of Conference"}
                    value={newConference?.name || ""}
                    placeholder={"Conference Name"}
                    onChange={handleInput}
                  />
                  <ModalInput
                    name="hostname"
                    label={"Host Name"}
                    value={newConference?.hostname || ""}
                    placeholder={"Host Name"}
                    onChange={handleInput}
                  />
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "48.5% 48.5%",
                    gridGap: "3%",
                  }}
                >
                  <ModalInput
                    name="startDate"
                    label={"Start Date"}
                    type="date"
                    value={
                      newConference.liveOn?.date
                        ? getFormattedDate(
                            new Date(newConference.liveOn.date),
                            "-",
                            "YY-MM-DD"
                          )
                        : ""
                    }
                    placeholder={"Conference Date"}
                    onChange={handleTimeInput}
                  />
                  <ModalInput
                    name="startTime"
                    label={"Start Time"}
                    type="time"
                    value={
                      newConference.liveOn?.time
                        ? get24Time(newConference.liveOn.time)
                        : ""
                    }
                    readOnly={newConference.liveOn?.date ? false : true}
                    placeholder={"Conference Start Time"}
                    onChange={handleTimeInput}
                  />
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "48.5% 48.5%",
                    gridGap: "3%",
                  }}
                >
                  <ModalInput
                    name="endDate"
                    label={"End Date"}
                    type="date"
                    value={
                      newConference.endOn?.date
                        ? getFormattedDate(
                            new Date(newConference.endOn.date),
                            "-",
                            "YY-MM-DD"
                          )
                        : ""
                    }
                    placeholder={"Conference End Date"}
                    onChange={handleTimeInput}
                  />
                  <ModalInput
                    name="endTime"
                    label={"End Time"}
                    type="time"
                    value={
                      newConference.endOn?.time
                        ? get24Time(newConference.endOn.time)
                        : ""
                    }
                    readOnly={newConference.endOn?.date ? false : true}
                    placeholder={"Conference End Time"}
                    onChange={handleTimeInput}
                  />
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "48.5% 48.5%",
                    gridGap: "3%",
                  }}
                >
                  <ModalInput
                    name="address"
                    label={"Address"}
                    value={newConference.address || ""}
                    placeholder={"Conference Address"}
                    onChange={handleInput}
                  />
                  <ModalInput
                    name="website"
                    label={"Website"}
                    value={newConference.website}
                    placeholder={"Conference Website"}
                    onChange={handleInput}
                  />
                </div>
                <div style={{ marginTop: "20px" }}>Other Information</div>
                <DynamicForm setNewConference={setNewConference} />
                <div style={{ marginTop: "20px" }}>
                  {validationErrors.length > 0
                    ? validationErrors.map((error, index) => (
                        <div
                          key={index}
                          style={{ color: "red", marginBottom: "5px" }}
                        >
                          {error}
                        </div>
                      ))
                    : null}
                </div>
                {loader ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "50px",
                    }}
                  >
                    <div class="loader_KfnSdkCmel"></div>
                  </div>
                ) : (
                  <Button
                    btnType={`${false ? "disabled" : ""} modalBtn`}
                    name="ADD"
                    onClick={() => createConference()}
                  />
                )}
              </ModalForm>
            </Modal>
          )}
        </Content>
      </Body>
    </Container>
  );
}

export default Conferences;
