import React, { useState } from "react";
import axios from "axios";

const PushNotificationModal = ({ button, attendees, conferenceId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setTitle("");
    setDescription("");
  };

  const handleSendNotification = async () => {
    try {
      const requestData = {
        attendees,
        title,
        description,
        conferenceId,
      };

      const response = await axios.post(
        "https://api.networkingtag.com/api/v1/notifications/send",
        requestData
      );

      // console.log("Notification sent successfully:", response.data);
      alert("Notification sent successfully");

      closeModal();
    } catch (error) {
      console.error("Error sending notification:", error.response || error);
      alert("Failed to send notification. Please try again.");
    }
  };

  return (
    <div>
      <div onClick={openModal} style={{ margin: "18px 0 0 0" }}>
        {button}
      </div>
      {isModalOpen && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "30px",
              borderRadius: "10px",
              width: "450px", // Increased width of the modal
              maxWidth: "90%",
              boxSizing: "border-box", // Ensures padding doesn't overflow width
              paddingRight: "55px",
            }}
          >
            <h3>Push Notification</h3>

            <div style={{ marginBottom: "15px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontSize: "16px",
                  color: "#333",
                }}
              >
                Notification Title
              </label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                style={{
                  width: "100%",
                  padding: "12px",
                  fontSize: "16px",
                  borderRadius: "8px", // Smoother border radius
                  border: "1px solid #ccc", // Light border
                  outline: "none", // Remove outline to customize focus
                  transition: "border-color 0.3s ease", // Smooth transition on focus
                }}
                placeholder="Enter title"
                onFocus={(e) => (e.target.style.borderColor = "#007BFF")} // Change border color on focus
                onBlur={(e) => (e.target.style.borderColor = "#ccc")} // Reset border color on blur
              />
            </div>

            <div style={{ marginBottom: "15px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontSize: "16px",
                  color: "#333",
                }}
              >
                Notification Description
              </label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={{
                  width: "100%",
                  padding: "12px",
                  fontSize: "16px",
                  borderRadius: "8px", // Smoother border radius
                  border: "1px solid #ccc", // Light border
                  outline: "none", // Remove outline to customize focus
                  transition: "border-color 0.3s ease", // Smooth transition on focus
                  resize: "vertical", // Allow vertical resizing
                  minHeight: "120px", // Set minimum height for textarea
                }}
                placeholder="Enter description"
                onFocus={(e) => (e.target.style.borderColor = "#007BFF")} // Change border color on focus
                onBlur={(e) => (e.target.style.borderColor = "#ccc")} // Reset border color on blur
              />
            </div>

            <div style={{ textAlign: "right" }}>
              <button
                onClick={closeModal}
                style={{
                  padding: "12px 20px",
                  fontSize: "16px",
                  marginRight: "10px",
                  cursor: "pointer",
                  backgroundColor: "gray",
                  color: "white",
                  border: "none",
                  borderRadius: "8px",
                  transition: "background-color 0.3s ease", // Smooth transition on hover
                }}
                onMouseEnter={(e) => (e.target.style.backgroundColor = "#555")} // Darker on hover
                onMouseLeave={(e) => (e.target.style.backgroundColor = "gray")} // Reset color on mouse leave
              >
                Cancel
              </button>
              <button
                onClick={handleSendNotification}
                style={{
                  padding: "12px 20px",
                  fontSize: "16px",
                  cursor: "pointer",
                  backgroundColor: "#007BFF",
                  color: "white",
                  border: "none",
                  borderRadius: "8px",
                  transition: "background-color 0.3s ease", // Smooth transition on hover
                }}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "#0056b3")
                } // Darker blue on hover
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "#007BFF")
                } // Reset color on mouse leave
              >
                Send
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PushNotificationModal;
