import { useEffect, useState, useRef } from "react";
import {
  Container,
  Body,
  Content,
  ContentHeader,
} from "../components/Container.Style";
import {
  Table,
  TableHeader,
  TableBody,
  Td,
  Tr,
} from "../components/Table.Style";
import Button from "../components/Input/Button";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import Search from "../components/Input/Search";

import ExportIcon from "../assets/images/export.svg";

import attendeeServices from "../services/attendee.services";

function Users() {
  const conferenceId = useRef("6450ef126edf8bb73cd14c78");
  const [search, setSearch] = useState("");
  const [attendees, setAttendees] = useState([]);
  const [progress, setProgress] = useState({
    inProgress: false,
    message: "",
  });

  function exportAttendees() {
    if (progress.inProgress) return;
    setProgress({
      inProgress: true,
      message: "Please wait, exporting attendees!",
    });
    attendeeServices
      .exportAttendees(conferenceId.current)
      .catch((err) => {
        alert(err.message);
      })
      .finally(() => {
        setProgress({
          inProgress: true,
          message: "Please wait, exporting attendees!",
        });
      });
  }

  useEffect(() => {
    //FETCH ATTENDEES
    attendeeServices
      .getAttendees({})
      .then((response) => {
        setAttendees(response.data.attendees);
      })
      .catch((err) => alert(err.message));
  }, []);

  useEffect(() => {
    attendeeServices
      .getAttendees(search ? `name[regex]=${search}&name[options]=i` : "")
      .then((response) => {
        setAttendees(response.data.attendees);
      })
      .catch((err) => alert(err.message));
  }, [search]);
  return (
    <Container>
      <Header name={""} />
      <Body>
        <Sidebar />
        <Content>
          <ContentHeader>
            <div className="head-block">
              <h3>User List</h3>
            </div>
            <div className="head-block right">
              <Search
                search={search}
                onChange={(event) => setSearch(event.target.value)}
              />
              <Button
                icon={ExportIcon}
                onClick={exportAttendees}
                btnType={`${false ? "disabled" : ""} exportBtn`}
                name="Export"
              />
            </div>
          </ContentHeader>
          <br />
          <Table>
            <TableHeader>
              <Tr borderWidth={"0px"} background={"transparent"}>
                <Td>S.No.</Td>
                <Td>Name</Td>
                <Td>Email</Td>
                <Td>Company</Td>
                <Td>Team</Td>
                <Td>Registered</Td>
                {/*<Td>Mail Status</Td>*/}
              </Tr>
            </TableHeader>
            <TableBody>
              {attendees.map((attendee, index) => {
                return (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td color="#000000">{attendee.name}</Td>
                    <Td>{attendee.email.replace(/\+.*@/, "@")}</Td>
                    <Td>{attendee.company || "-"}</Td>
                    <Td color={attendee.team ? "#000000" : "#a0a0a0"}>
                      {attendee.team || "Not Assigned"}
                    </Td>
                    <Td color={attendee.registered ? "green" : "#a0a0a0"}>
                      {attendee.registered ? "Registered" : "Pending"}
                    </Td>
                    {/*<Td color={attendee.mailSend ? "green" : "#a0a0a0"}>
                      {attendee.mailSend ? "Sent" : "Pending"}
                </Td>*/}
                  </Tr>
                );
              })}
            </TableBody>
          </Table>
        </Content>
      </Body>
    </Container>
  );
}

export default Users;
