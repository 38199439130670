import React, { useEffect, useState } from "react";

const DynamicForm = ({ setNewConference }) => {
  const [fields, setFields] = useState([]);

  const handleAddField = () => {
    setFields([
      ...fields,
      // {
      //   label: "",
      //   disable: false,
      //   visibility: false,
      //   outer: false,
      //   outerKey: "",
      //   icon: null,
      //   type: "",
      //   options: [],
      // },
      {
        label: "Company",
        disable: false,
        visibility: false,
        outer: true,
        outerKey: "company",
        icon: null,
        type: "text",
        options: [],
      },
      {
        label: "State",
        disable: false,
        visibility: false,
        outer: false,
        outerKey: "",
        icon: null,
        type: "text",
        options: [],
      },
      {
        label: "Business Type",
        disable: false,
        visibility: true,
        outer: false,
        outerKey: "",
        icon: null,
        type: "dropdown",
        options: [
          "Bank/Financial Institution",
          "BPO/Service Provider",
          "Consultant",
          "Consumer Product/Service/Utility/Healthcare",
          "Debt Buyer",
          "Digital Lender (Fintech)",
          "Government",
          "Law Firm",
          "Technology Provider",
          "Other",
        ],
      },
      {
        label: "Job Title",
        disable: false,
        visibility: false,
        outer: false,
        outerKey: "",
        icon: null,
        type: "text",
        options: [],
      },
      {
        label: "Job Level",
        disable: false,
        visibility: true,
        outer: false,
        outerKey: "",
        icon: null,
        type: "dropdown",
        options: [
          "Corporate Office/Executive",
          "VP/Division Head",
          "Manager/Department Lead",
          "Professional",
          "Coordinator/Assistant",
          "Other",
        ],
      },
      {
        label: "Role",
        disable: false,
        visibility: true,
        outer: false,
        outerKey: "",
        icon: null,
        type: "dropdown",
        options: [
          "Customer Relations",
          "Finance/Accounting",
          "Credit Risk & Underwriting",
          "General Executive Management",
          "Legal",
          "Marketing",
          "Operations",
          "Product Management",
          "Risk & Compliance",
          "Sales & Business Development",
          "Technology",
          "Other",
        ],
      },
      {
        label: "Your prior WCF attendance",
        disable: false,
        visibility: true,
        outer: false,
        outerKey: "",
        icon: null,
        type: "dropdown",
        options: [
          "I've attended in person",
          "I've only attended virtually",
          "My first time",
        ],
      },
      {
        label: "Your interaction style",
        disable: false,
        visibility: true,
        outer: false,
        outerKey: "",
        icon: null,
        type: "dropdown",
        options: [
          "Primarily Introvert",
          "Primarily Extrovert",
          "A true balance of both",
        ],
      },
    ]);
  };

  const handleDeleteField = (index) => {
    const updatedFields = fields.filter((_, i) => i !== index);
    setFields(updatedFields);
  };

  const handleFieldChange = (index, event) => {
    const updatedFields = [...fields];
    const value = event.target.value;
    if (value == "") return;
    // console.log(value);
    updatedFields[index][event.target.name] = value;
    // updatedFields[index].outerKey = `${value
    //   .toString()
    //   .toLowerCase()}_${Math.floor(10000 + Math.random() * 90000)}`;
    setFields(updatedFields);
  };

  const handleOptionsChange = (index, event) => {
    const updatedFields = [...fields];
    const options = event.target.value
      .split(",")
      .map((option) => option.trim());
    updatedFields[index].options = options;
    setFields(updatedFields);
  };

  const handleSubmit = () => {
    console.log(fields);
  };

  const convertArrayToObject = (arr) => {
    const result = {};

    arr.forEach((item) => {
      if (item.label.trim() != "") {
        if (item.type == "dropdown") {
          if (item.options.length > 0) {
            result[item.label] = { ...item };
          }
        } else {
          result[item.label] = { ...item };
        }
      }
    });

    return result;
  };

  useEffect(() => {
    handleAddField();
  }, []);

  useEffect(() => {
    const convertedObject = convertArrayToObject(fields);
    setNewConference((prev) => {
      prev.otherInfo = convertedObject;
      return prev;
    });
  }, [fields]);

  return (
    <div style={styles.container}>
      {fields.map((field, index) => (
        <div key={index} style={styles.fieldContainer}>
          <div style={styles.inputGroup}>
            <label style={styles.label}>Type:</label>
            <select
              name="type"
              value={field.type}
              onChange={(event) => handleFieldChange(index, event)}
              style={styles.select}
            >
              <option value="">Please select type</option>
              <option value="text">Text</option>
              <option value="dropdown">Dropdown</option>
            </select>
          </div>

          {field.type != "" ? (
            <div style={styles.inputGroup}>
              <label style={styles.label}>Label:</label>
              <input
                type="text"
                name="label"
                value={field.label}
                onChange={(event) => handleFieldChange(index, event)}
                placeholder="Enter label"
                style={styles.input}
              />
            </div>
          ) : null}

          {field.type === "dropdown" && (
            <div style={styles.inputGroup}>
              <label style={styles.label}>Options (comma separated):</label>
              <input
                type="text"
                value={field.options.join(", ")}
                onChange={(event) => handleOptionsChange(index, event)}
                placeholder="Enter options (comma separated)"
                style={styles.input}
              />
            </div>
          )}

          <button
            onClick={() => handleDeleteField(index)}
            style={styles.deleteButton}
          >
            Delete Field
          </button>
        </div>
      ))}

      <div style={styles.buttonContainer}>
        <button onClick={handleAddField} style={styles.addButton}>
          Add Other Info Field
        </button>
        {/* <button onClick={handleSubmit} style={styles.submitButton}>
          Submit
        </button> */}
      </div>
    </div>
  );
};

const styles = {
  container: {
    // maxWidth: "600px",
    // margin: "0 auto",
    padding: "20px",
    backgroundColor: "#f4f4f4",
    borderRadius: "12px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    marginTop: "10px",
  },
  header: {
    textAlign: "center",
    fontSize: "24px",
    marginBottom: "20px",
    color: "#333",
  },
  fieldContainer: {
    backgroundColor: "#fff",
    padding: "15px",
    borderRadius: "8px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    marginBottom: "15px",
  },
  inputGroup: {
    marginBottom: "10px",
  },
  label: {
    display: "block",
    marginBottom: "5px",
    fontSize: "14px",
    color: "#555",
  },
  input: {
    width: "97%",
    padding: "10px",
    fontSize: "14px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  select: {
    width: "100%",
    padding: "10px",
    fontSize: "14px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  deleteButton: {
    backgroundColor: "#e74c3c",
    color: "#fff",
    border: "none",
    padding: "8px 16px",
    fontSize: "14px",
    borderRadius: "4px",
    cursor: "pointer",
    marginTop: "10px",
    transition: "background-color 0.3s",
  },
  deleteButtonHover: {
    backgroundColor: "#c0392b",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    backgroundColor: "#f4f4f4",
  },
  addButton: {
    backgroundColor: "#F7AD00",
    color: "#fff",
    border: "none",
    padding: "10px 20px",
    fontSize: "16px",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  submitButton: {
    backgroundColor: "#3498db",
    color: "#fff",
    border: "none",
    padding: "10px 20px",
    fontSize: "16px",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
};

export default DynamicForm;
